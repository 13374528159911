import { FiGithub, FiLinkedin,FiSend } from "react-icons/fi";

const socialLinks = [
  {
    id: 2,
    icon: <FiGithub />,
    url: "https://github.com/Yehya22",
  },

  {
    id: 4,
    icon: <FiLinkedin />,
    url: "https://www.linkedin.com/in/yehyashaaban",
  },
  {
    id: 6,
    icon: <FiSend />,
    url: "https://t.me/YehyaShaaban",
  },
];

const AppFooter = () => {
  return (
    <div id="contact" className="container mx-auto">
      <div className="pt-20 sm:pt-30 pb-8 mt-20 border-t-2 border-primary-light dark:border-secondary-dark">
        {/* Footer social links */}
        <div className="font-general-regular flex flex-col justify-center items-center mb-12 sm:mb-28">
          <p className="text-3xl sm:text-4xl text-primary-dark dark:text-primary-light mb-5">
            Contact with me
          </p>
          <ul className="flex gap-4 sm:gap-8">
            {socialLinks.map((link) => (
              <a
                href={link.url}
                target="__blank"
                key={link.id}
                className="text-gray-400 hover:text-indigo-500 dark:hover:text-indigo-400 cursor-pointer rounded-lg bg-gray-50 dark:bg-ternary-dark hover:bg-gray-100 shadow-sm p-4 duration-300"
              >
                <i className="text-xl sm:text-2xl md:text-3xl">{link.icon}</i>
              </a>
            ))}
          </ul>
          <p className="footer-text p-5">
            If you're looking for a collaborative, innovative, and
            results-driven web developer, I'm here to help. I'm always open to
            new opportunities and challenges, and I believe that the best
            results come from working closely with clients to understand their
            unique needs and goals. Whether you need a website built from
            scratch or an existing site updated and optimized, I have the skills
            and expertise to make it happen. Contact me directly through my
             social media accounts, and let's work together to achieve
            your web development goals.
          </p>
        </div>
      </div>

    </div>

  );
};

export default AppFooter;
